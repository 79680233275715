@import '../../../interface/styling/settings/vars.scss';

.language-menu {
  position: relative;

  > .button {
    justify-content: space-between;
    min-width: 150px !important;
    height: 40px !important;
    border-radius: 20px !important; 
    font-stretch: $font-stretch-normal;
    text-transform: none;

    .icon {
      margin: 0 -10px 0 10px;
      order: 1;
    }
  }

  >  ul {
    position: absolute;
    right: 0;
    width: 100%;
    margin: 4px 0 0;
    background-color: var(--color-white);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .12);
    border-radius: 8px;

    li {
      + li {
        border-top: 1px solid var(--color-gray);
      }

      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 44px;
        font-size: 14px;
        font-weight: $font-weight-bold;
        padding: 0 15px;

        img {
          margin: 0 10px 0 0;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}