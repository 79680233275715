@import '../../../interface/styling/settings/mixins.scss';

.pdf-viewer-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: var(--color-white);
  background: url('../../../interface/img/pdf-viewer-background.png') no-repeat center center;
  background-size: 100% 100%;
  transition: opacity 0.3s;
  border-radius: 0;
  overflow: hidden;
  cursor: default;
  opacity: 0;
  z-index: 1;

  img {
    width: auto;
    height: 40px;
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 90px;
    padding: 20px;
    z-index: 1;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      display: block;
      width: 52px;
      height: 52px;
      font-size: 0;
      padding: 0;
      background: alpha(--color-white, 0.08);
      border-radius: 50%;

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 18px;
        height: 18px;
        margin: -9px 0 0 -9px;
        content: '';
        background: url('../../../interface/img/icons/close.svg') no-repeat center center;
        filter: var(--filter-white);
      }

      &:hover {
        &::after {
          filter: var(--filter-dark-cyan);
        }
      }
    }
  }

  &__content {
    position: relative;
    flex: 1;
    z-index: 0;
  }

  &.active {
    opacity: 1;
  }
}