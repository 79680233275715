@import './../../interface/styling/settings/vars.scss';

.button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  color: var(--color-white);
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight-bold;
  font-stretch: $font-stretch-condensed;
  text-transform: uppercase;
  text-decoration: none;
  background: var(--color-cyan);
  border: 1px solid var(--color-cyan);
  padding: 9px 20px;

  .icon {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 -10px;
    filter: var(--filter-white);

    &:only-child {
      margin: 0 -10px;
    }
  }

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .spinner {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 -10px;
    stroke: var(--color-white);

    &:only-child {
      margin: 0 -10px;
    }
  }

  .badge {
    position: absolute;
    top: -4px;
    right: -4px;
    display: block;
    width: 24px;
    height: 24px;
    color: var(--color-white);
    font-size: 15px;
    line-height: 24px;
    font-weight: $font-weight-bold;
    text-align: center;
    background: var(--color-red);
    border-radius: 50%;

    ~ .icon,
    ~ .spinner {
      &:last-child {
        margin: 0 -10px;
      }
    }
  }

  &:hover {
    color: var(--color-white);
    border-color: var(--color-dark-cyan);
    background: var(--color-dark-cyan);

    .icon {
      filter: var(--filter-white);
    }

    .spinner {
      stroke: var(--color-white);
    }
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
    color: var(--color-dark-gray);
    background-color: var(--color-light-gray);
    border-color: var(--color-light-gray);

    .icon {
      filter: var(--filter-dark-gray) !important;
    }

    .spinner {
      stroke: var(--color-dark-gray);
    }
  }

  &.small {
    min-width: 38px;
    height: 38px;
    padding: 9px 18px;
  }

  &.active {
    &::after {
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      border-radius: 50%;
      background-color: var(--color-red);
    }
  }

  &.icon {
    border: 0;
    padding: 0;
    border-radius: 50%;
    background: var(--color-light-gray);

    &:hover {
      background: var(--color-gray);
    }

    .icon {
      width: 20px;
      height: 20px;
      margin: 0 -10px;
      filter: var(--filter-black);
    }

    &[disabled] {
      .icon {
        filter: var(--filter-dark-gray) !important;
      }

      .spinner {
        stroke: var(--color-dark-gray);
      }
    }
  }

  &.primary {
    &:not(:disabled) {
      color: var(--color-white);
      background: var(--color-cyan);
      border-color: var(--color-cyan);

      &:hover {
        color: var(--color-white);
        background: var(--color-dark-cyan);
        border-color: var(--color-dark-cyan);
    
        .icon {
          filter: var(--filter-white);
        }
    
        .spinner {
          stroke: var(--color-white);
        }
      }
    }
  }

  &.secondary {
    &:not(:disabled) {
      color: var(--color-black);
      background-color: transparent;
      border-color: var(--color-black);
      font-weight: $font-weight-normal;

      &:hover {
        color: var(--color-dark-cyan);
        background: transparent;
        border-color: var(--color-dark-cyan);
    
        .icon {
          filter: var(--filter-dark-cyan);
        }
    
        .spinner {
          stroke: var(--color-dark-cyan);
        }
      }
    }
  }

  &.danger {
    &:not(:disabled) {
      background-color: var(--color-red);
      border-color: var(--color-red);

      &:hover {
        color: var(--color-red);
        background-color: transparent;

        .icon {
          filter: var(--filter-red);
        }
    
        .spinner {
          stroke: var(--color-red);
        }
      }
    }
  }

  &.link {
    height: initial;
    color: var(--text-color);
    text-decoration: underline;
    background: transparent;
    border: 0;
    padding: 0;

    .icon {
      margin: 0 10px 0 0;
      filter: var(--filter-text-color);

      &:only-child {
        margin: 0 ;
      }
    }

    &:hover {
      color: var(--text-color);
      text-decoration: none;
    }

    &[disabled] {
      color: var(--color-dark-gray);
      text-decoration: none;
    }

    &.danger {
      color: var(--color-red);
      background: transparent;

      .icon {
        filter: var(--filter-red);
      }
    }
  }

  &.transparent {
    color: var(--text-color);
    background: transparent;

    .icon {
      filter: var(--filter-secondary);
    }
  }

  &.light {
    color: var(--color-black);
    font-weight: $font-weight-bold;
    background: var(--color-white);
    border-color: var(--color-gray);

    .icon {
      filter: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &.round {
    border-radius: 20px;
  }
}