@import '../../interface/styling/settings/vars.scss';

.block {
  position: relative;
  display: flex;
  flex-direction: column;
  aspect-ratio: 278 / 411;
  cursor: pointer;
  margin: 0 0 $default-margin;

  &__image {
    position: relative;
    height: 100%;
    overflow: hidden;

    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 100%;
      height: 50%;
      content: '';
      background: rgb(0,0,0);
      background: linear-gradient(0deg, var(--color-blue) 0%, transparent 100%);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform .3s;
    }
  }

  &__content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 30px;

    h2 {
      display: flex;
      align-items: center;
      color: var(--color-white);
      font-size: 20px;
      line-height: 26px;

      &::after {
        display: inline-block;
        width: 20px;
        height: 20px;
        content: '';
        background: url('../../interface/img/icons/chevron.svg') no-repeat center center;
        filter: var(--filter-white);
        transform: rotate(-90deg);
        margin: 0 0 0 5px;
      }
    }
  }

  &:hover {
    .block {
      &__image {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
}