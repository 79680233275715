@mixin defineColorVar($color-name, $value) {
  $red: red($value);

  $green: green($value);

  $blue: blue($value);

  #{$color-name}: unquote("rgb(#{$red}, #{$green}, #{$blue})");

  #{$color-name}-rgb: $red, $green, $blue;

  #{$color-name}-r: $red;
  #{$color-name}-g: $green;
  #{$color-name}-b: $blue;
}

// replace substring with another string
// credits: https://css-tricks.com/snippets/sass/str-replace-function/
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function alpha($color, $opacity) {
  $color: str-replace($color, 'var(');
  $color: str-replace($color, ')');

  $color-r: var(#{$color + '-r'});
  $color-g: var(#{$color + '-g'});
  $color-b: var(#{$color + '-b'});

  @return rgba($color-r, $color-g, $color-b, $opacity);
}