@font-face {
  font-family: 'TradeGothicNext';
  src: url('../../fonts/TradeGothicNextLTW05-Rg.woff') format('woff'),
    url('../../fonts/TradeGothicNextLTW05-Rg.woff2') format('woff2');
  font-weight: 'normal';
}

@font-face {
  font-family: 'TradeGothicNext';
  src: url('../../fonts/TradeGothicNextLTW05-Cond.woff') format('woff'),
    url('../../fonts/TradeGothicNextLTW05-Cond.woff2') format('woff2');
  font-weight: 'normal';
  font-stretch: 'condensed';
}

@font-face {
  font-family: 'TradeGothicNext';
  src: url('../../fonts/TradeGothicNextLTW05-Light.woff') format('woff'),
    url('../../fonts/TradeGothicNextLTW05-Light.woff2') format('woff2');
  font-weight: 'lighter';
}

@font-face {
  font-family: 'TradeGothicNext';
  src: url('../../fonts/TradeGothicNextLTW05-Bold.woff') format('woff'),
    url('../../fonts/TradeGothicNextLTW05-Bold.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'TradeGothicNext';
  src: url('../../fonts/TradeGothicNextLTW05-BoldCn.woff') format('woff'),
    url('../../fonts/TradeGothicNextLTW05-BoldCn.woff2') format('woff2');
  font-weight: bold;
  font-stretch: condensed;
}