@import '../../interface/styling/settings/vars.scss';

.section {
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  margin: 0 0 $default-margin;

  &__content {
    padding: $default-margin 40px 0;

    .section-columns {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -15px;
      
      &__column {
        flex: 1;
        padding: 0 15px;
      }
    }
  }
}