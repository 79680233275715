@import 'bootstrap/scss/functions';
@import './settings/fonts';
@import './settings/vars';
@import './settings/mixins';
@import './settings/responsive';

@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/forms';

*,
*::before,
*::after {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}

html,
body {
  font-family: $font;
  color: var(--text-color);
  font-weight: $font-weight-normal;
  font-size: $font-size;
  line-height: $line-height;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  background: var(--color-gray);
}

body {
  position: relative;
  z-index: 1;

  &.modal-open {
    overflow: hidden;
  }
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

a {
  color: var(--text-color);
  text-decoration: underline;

  &:hover {
    color: var(--text-color);
    text-decoration: underline;
  }
}

h1 {
  color: var(--text-color);
  font-size: 32px;
  line-height: 48px;
  font-weight: $font-weight-bold;
  font-stretch: $font-stretch-condensed;

  @include media-breakpoint-down(lg) {
    font-size: 22px;
    line-height: 32px;
  }
}

h2 {
  color: var(--text-color);
  font-size: 40px;
  line-height: 50px;
  font-weight: $font-weight-bold;
  font-stretch: $font-stretch-condensed;
}

h3 {
  color: var(--text-color);
  font-size: 28px;
  line-height: 38px;
  font-weight: $font-weight-bold;
  font-stretch: $font-stretch-condensed;
}

h4,
h5,
h6 {
  color: var(--text-color);
  font-size: 18px;
  line-height: 22px;
  font-weight: $font-weight-bold;
  font-stretch: $font-stretch-normal;

  + p,
  +.richtext {
    margin-top: -$default-margin;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  small {
    font-weight: $font-weight-normal;
    margin: 0 0 0 5px;
    text-transform: none;
  }
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

p,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
form,
table,
dl,
hr,
address,
blockquote {
  margin: 0 0 $default-margin;
}

button {
  font-family: $font;
  color: var(--text-color);
  font-size: $font-size;
  line-height: $line-height;
  cursor: pointer;
  border: 0;
  outline: none;
  box-shadow: none;
  background: transparent;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

table {
  table-layout: fixed;
  width: calc(100% + 20px);
  margin: 0 -10px $default-margin;
  border-collapse: collapse;

  th,
  td {
    padding: 10px;

    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  th {
    font-weight: $font-weight-black;
  }

  thead {
    tr {
      border-bottom: 1px solid var(--color-gray);
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--color-gray);

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.table {
  border: 1px solid var(--color-gray);
  border-radius: 8px;
  margin: 0 0 $default-margin;

  table {
    width: 100%;
    margin: 0;
  }
}

hr {
  border: 0;
  height: 1px;
  background: var(--color-gray);
  margin: 0 0 $default-margin;
  opacity: 1;
}

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: no-repeat center center;

  @each $icon in $icons {
    &.#{$icon} {
      background-image: url('../../interface/img/icons/' + $icon + '.svg');
    }
  }
}

.richtext {
  display: flex;
  flex-direction: column;
  margin: 0 0 calc($default-margin / 2);

  > * {
    margin: 0 0 calc($default-margin / 2);
  }

  small {
    font-size: 12px;
    line-height: 16px;
    color: var(--color-light-blue);
  }

  + .richtext {
    margin-top: - calc($default-margin / 2);
  }
}

#modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: none;

  > * {
    pointer-events: all;
  }
}

.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 90px;
  color: var(--color-white);
  background: var(--color-blue);
  padding: 10px 20px;

  &__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 25%;
    max-width: 25%;

    > * {
      + * {
        margin-left: 40px;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 25%;
    max-width: 25%;

    > * {
      + * {
        margin-left: 40px;
      }
    }
  }

  &__center {
    display: flex;
    justify-content: center;
    flex: 0 0 50%;
    max-width: 50%;

    a {
      display: block;

      img {
        width: auto;
        height: 40px;
      }
    }
  }

  .button {
    &.round {
      min-width: 52px;
      height: 52px;
      border-radius: 26px;
      background: alpha(--color-white, 0.08);
      border: 0;

      .icon {
        &.logout {
          width: 24px;
          height: 24px;
          margin: 0 -12px;
        }
      }

      &:hover {
        color: var(--color-dark-cyan);
        
        .icon {
          filter: var(--filter-dark-cyan);
        }

        .spinner {
          stroke: var(--color-dark-cyan);
        }
      }
    }
  }
}

.page-content {
  flex: 1;
  padding: 0;
  overflow-x: scroll;

  &__content {
    max-width: 1296px;
    margin: 0 auto;
    padding: 30px 48px 30px - $default-margin;
  }
}

.overview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -15px;

  &__item {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 15px;
  }
}