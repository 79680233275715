@import '../../../interface/styling/settings/vars.scss';

.loading-message {
  display: flex;
  flex-direction: column;
  margin: 0 0 $default-margin;

  &__content {
    display: flex;
    justify-content: center;
    
    p {
      margin: 0;
    }
    
    .spinner {
      stroke: var(--text-color);
      margin: 0 15px 0 0;
    }
  }
}