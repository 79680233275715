@import './mixins.scss';

$grid-columns: 12 !default;
$enable-dark-mode: false !default;
$default-margin: 30px;

$font: 'TradeGothicNext', serif;

$font-size: 15px;
$line-height: 22px;

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

$font-stretch-normal: normal;
$font-stretch-condensed: condensed;

$icons: 'logout' 'back' 'chevron';

body {
  @include defineColorVar(--text-color, #000000);
  @include defineColorVar(--color-black, #000000);
  @include defineColorVar(--color-white, #ffffff);
  @include defineColorVar(--color-gray, #f7f9fb);
  @include defineColorVar(--color-dark-gray, #d5d8dc);
  @include defineColorVar(--color-light-gray, #f4f4f4);
  @include defineColorVar(--color-blue, #0c2340);
  @include defineColorVar(--color-light-blue, #85919f);
  @include defineColorVar(--color-cyan, #0ac8be);
  @include defineColorVar(--color-dark-cyan, #08aaa1);

  --filter-black: none;
  --filter-white: invert(100%) sepia(0%) saturate(1%) hue-rotate(120deg) brightness(104%) contrast(101%);
  --filter-gray: invert(93%) sepia(10%) saturate(29%) hue-rotate(168deg) brightness(98%) contrast(110%);
  --filter-dark-gray: invert(95%) sepia(9%) saturate(91%) hue-rotate(175deg) brightness(93%) contrast(86%);
  --filter-light-gray: invert(99%) sepia(5%) saturate(124%) hue-rotate(220deg) brightness(115%) contrast(91%);
  --filter-blue: invert(13%) sepia(7%) saturate(5956%) hue-rotate(175deg) brightness(95%) contrast(100%);
  --filter-cyan: invert(74%) sepia(29%) saturate(7256%) hue-rotate(132deg) brightness(97%) contrast(92%);
  --filter-dark-cyan: invert(43%) sepia(80%) saturate(1141%) hue-rotate(143deg) brightness(93%) contrast(94%);
}
